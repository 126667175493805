import request from '@/utils/request';

export function getLineNotifyBindingPage() {
  return request({
    url: 'line_notify_page',
    method: 'get'
  });
}

export default {
  getLineNotifyBindingPage
};
