export default {
  data() {
    return {
      oauthProvider: process.env.VUE_APP_OAUTH_PROVIDER
    };
  },
  computed: {
    isFacebook() {
      return this.oauthProvider === 'facebook';
    },
    isLINE() {
      return this.oauthProvider === 'line';
    },
    isTelegram() {
      return this.oauthProvider === 'telegram';
    },
    contentBodyClass() {
      let contentClass = '';

      if (this.isFacebook === true) {
        contentClass = 'fb';
      }

      if (this.isLINE === true) {
        contentClass = 'line';
      }

      if (this.isTelegram === true) {
        contentClass = 'tele';
      }

      return contentClass;
    }
  }
};
