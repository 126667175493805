<template>
  <div>
    <div :class="['login-top', topHeaderClass]">
      <div class="esy-logo">
        <img :src="require('@/assets/img/logo.jpeg')" alt="" />
      </div>
    </div>
    <div class="main-content">
      <div class="content-block">
        <div class="container" v-html="pageContent">
          <!-- <LoginContent
            :agreeTerm="agreeTerm"
            @update="updateAgreeTerm"
            @showTerm="showTerm"
          ></LoginContent> -->
        </div>
      </div>
      <div class="bottom-btn-block">
        <a class="login-btn line" @click.prevent="bindingLineNotify">
          連結 Line Notify 服務
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AppPlatformMixin from '@/mixins/app-platform';
import queryString from 'querystring';
import { bindLineNotify } from '@/api/member';
import { getLineNotifyBindingPage } from '@/api/line-notify-page';
import { redirectAfterAuth } from '@/utils/redirect-to';

export default {
  mixins: [AppPlatformMixin],
  data() {
    return {
      clientId: process.env.VUE_APP_LINE_NOTIFY_CLIENT_ID,
      clientSecret: process.env.VUE_APP_LINE_NOTIFY_CLIENT_SECRET,
      redirectUri: process.env.VUE_APP_LINE_NOTIFY_REDIRECT,
      pageContent: null
    };
  },
  computed: {
    topHeaderClass() {
      return this.contentBodyClass;
    }
  },
  methods: {
    bindingLineNotify() {
      let state = Math.random()
        .toString(36)
        .slice(2);

      location.href =
        'https://notify-bot.line.me/oauth/authorize?' +
        'response_type=code' +
        `&client_id=${this.clientId}` +
        `&redirect_uri=${this.redirectUri}` +
        '&scope=notify' +
        `&state=${state}`; //自定義String防止CSRF攻擊
    },
    getLineNotifyBindingPage() {
      getLineNotifyBindingPage().then(data => {
        this.pageContent = data.content;
      });
    }
  },
  async created() {
    this.getLineNotifyBindingPage();
    const { code } = queryString.parse(window.location.search.replace('?', ''));

    if (!code) return;

    try {
      await bindLineNotify(code, true);

      redirectAfterAuth();
    } catch (e) {
      alert('綁定失敗，請稍後再重新嘗試綁定，或聯絡客服');
      console.log(e);
    }
  }
};
</script>
